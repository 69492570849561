<template>
  <Error variant="404" />
</template>

<script>
import Error from "@/components/Error.vue";

/**
 * Affiche la page 404.
 */
export default {
  name: "ErrorPage",
  components: {
    Error,
  },
};
</script>

<style lang="scss"></style>
