<template>
  <div id="error">
    <div id="zone_animation">
      <span
        v-for="n in 5"
        :id="`square${n}`"
        :key="n"
      />
    </div>

    <div class="grid-zone">
      <Toast
        v-bind="toastAttr"
        variant="error"
        show-icon
        hide-close-btn
      />

      <div id="block_left">
        <div class="text-regular">
          <h2
            class="s4 light"
            v-html="variant === 'no_services'
              ? $t('error-page.contactez-nous-au-plus-vite')
              : $t('error-page.retrouvez-votre-chemin')"
          />
          <p
            v-if="variant === 'no_services'"
            id="text_no_services"
            class="text-regular bold"
          >
            {{ $t("accueil.decouvrez-nouvel-univers-contact") }}
            <a
              class="s4 light"
              :href="`tel:${isDe ? '07851485990' : '0388391707'}`"
            >
              {{ isDe ? "07851 485990-0" : "03 88 39 17 07" }}
            </a>
            <span>{{ $t("general.ou") }}</span>
            <ButtonClassic
              variant="ghost"
              color="primary"
              icon="left"
              :label="$t('accueil.par-mail')"
              @click="copyToClipboard(isDe ? mailLdeDe : mailLdeFr)"
            >
              <template #left-icon>
                <UilEnvelope />
              </template>
            </ButtonClassic>
          </p>

          <ul
            v-else
            id="choices"
            class="text-regular"
          >
            <li>
              <strong>{{ $t('error-page.utilisant-navigation') }}</strong>
            </li>
            <li class="flex-vcenter">
              <strong>{{ $t('error-page.en-vous-laissant-guider-vers-la') }}</strong>
              <ButtonClassic
                :label="$t('error-page.page-precedente')"
                variant="ghost"
                color="primary"
                icon="left"
                :disabled="!hasPreviousPage"
                @click="$router.go(-1)"
              >
                <template #left-icon>
                  <UilArrowUpLeft size="16" />
                </template>
              </ButtonClassic>
              <ButtonClassic
                balise-type="router-link"
                :label="$t('error-page.page-accueil')"
                variant="ghost"
                color="primary"
                icon="left"
                :router-link-obj="{ name: 'accueil', }"
              >
                <template #left-icon>
                  <UilEstate size="16" />
                </template>
              </ButtonClassic>
            </li>
            <li class="flex-vcenter">
              <strong>{{ $t('error-page.en-nous-signalant-cette-erreur') }}</strong>
              <ButtonClassic
                v-bind="btnContactAttr"
                color="secondary"
                icon="left"
                @click="isDe && copyToClipboard(mailLdeDe)"
              >
                <template #left-icon>
                  <UilEnvelope size="16" />
                </template>
              </ButtonClassic>
            </li>
          </ul>
        </div>
      </div>
      <figure>
        <img
          id="illus_logo_design"
          :src="require(
            `@lde/core_lde_vue/dist/assets/media/illus/${
              variant === 'no_services' ? 'illus_elearning' : 'illus_logo_design'}.svg`)"
          :alt="$t('error-page.illustration-page-erreur')"
        />
      </figure>
    </div>
  </div>
</template>

<script>
import { ButtonClassic, Toast, copyToClipboard } from "@lde/core_lde_vue";

import { UilArrowUpLeft, UilEstate, UilEnvelope } from "@iconscout/vue-unicons";

import config from "@/config";

import { mapGetters } from "vuex";

/**
 * Permet d'afficher un contenu lié à une erreur spécifique (ex : 403, 404, pas de services...).
 */
export default {
  name: "Error",
  components: {
    ButtonClassic,
    Toast,
    UilArrowUpLeft,
    UilEstate,
    UilEnvelope,
  },
  props: {
    /**
     * Définit le type d'erreur.
     */
    variant: {
      type: String,
      validator: (value) => ["404", "no_services"].includes(value),
      default: "404",
    },
  },
  computed: {
    ...mapGetters(["country"]),
    isDe() {
      return this.country === "de";
    },
    hasPreviousPage() {
      return window.history.length > 1;
    },
    btnContactAttr() {
      if (!this.isDe) {
        return {
          baliseType: "a",
          extLink: process.env.VUE_APP_HOTLINE_URL,
          label: this.$t("general.contact"),
        };
      }
      return {
        baliseType: "button",
        label: this.mailLdeDe,
      };
    },
    mailLdeFr() {
      return config.mailsFr.lde;
    },
    mailLdeDe() {
      return config.mailsDe.lde;
    },
    toastAttr() {
      const toastAttr = {
        title: this.variant === "no_services" ? this.$t("error-page.oups") : this.$t("error-page.page-introuvable"),
      };
      if (this.variant === "no_services") {
        toastAttr.content = this.$t("error-page.nous-ne-parvenons-pas-identifier-services");
      }

      return toastAttr;
    },
  },
  methods: {
    copyToClipboard,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/error.scss";
@use "@/assets/styles/_carres_animation.scss";
</style>
